import styled from 'styled-components'
import breakpoint, { createStatic } from 'styled-components-breakpoint'
import { primaryFont, secondaryFont } from '../../GlobalStyles'
import Img from '../../components/Img'

const platesBreakpoints = createStatic({ tablet: 600 })

export const MainSection = styled.div`
  ${secondaryFont};
`

export const Mobile = styled.div`
  ${breakpoint('tablet')`
    display: none;
  `}
`

export const Tablet = styled.div`
  display: none;
  ${breakpoint('tablet')`
    display: flex;
  `};
  justify-content: space-between;
  & > * {
    width: 48%;
  }
`

export const MeetText = styled.span`
  white-space: pre-line;
  font-size: 20px;
`

export const Header = styled.h2`
  ${primaryFont};
  font-size: 67px;
  margin: 0 0 10px;
  font-weight: normal;
  white-space: pre-line;
`

export const Lexicon = styled.div`
  text-align: right;
  font-size: 18px;
  ${primaryFont};
  ${platesBreakpoints.tablet`
    margin-bottom: 0;
  `};
  margin-bottom: 40px;
  margin-top: 100px;
`

export const PlatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  ${platesBreakpoints.tablet`
    height: 660px;
  `};
  & > div {
    width: 100%;
    ${platesBreakpoints.tablet`
      width: 40%;
    `};
  }
`

export const PlateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const PlateTitle = styled.div`
  ${secondaryFont};
  display: flex;
  justify-content: ${props => (props.isFirst ? 'space-between' : 'flex-end')};
`

export const PlateDescription = styled.h4`
  ${primaryFont};
  font-size: 18px;
  font-weight: normal;
  width: 80%;
  margin: 0 0 0 auto;
  min-width: 200px;
`

export const MeetUsImg = styled(Img).attrs({
  filename: 'meetus-story.png',
  fadeIn: false
})`
  margin-top: 20px;
  margin-bottom: 10px;
`

export const RightAlignText = styled(MeetText)`
  text-align: right;
  display: block;
`

export const ImageMapWrapper = styled.div`
  position: relative;
  margin-bottom: 35px;
`

export const ProducersImage = styled(Img).attrs({
  filename: 'producers.png',
  fadeIn: false
})``

export const SophiaWrapper = styled.div`
  position: absolute;
  right: -30px;
  top: 0;
  width: 70vw;
  min-width: 250px;
  ${Tablet} & {
    top: 50px;
    width: 35vw;
  }
`

export const SophiaImage = styled(Img).attrs({
  filename: 'sophia.png',
  fadeIn: false
})``

export const SophiaText = styled(MeetText)`
  ${primaryFont};
  text-align: right;
  display: block;
  font-size: 15px;
  line-height: 1;
  position: absolute;
  top: 40%;
  left: 47%;
`

export const ProducersHeader = styled(Header)`
  text-align: right;
  position: absolute;
  top: calc(100% + 50px);
  right: 5vw;
  ${Tablet} & {
    display: none;
  }
`

export const ProducersHeaderTablet = styled(Header)`
  text-align: right;
  margin-top: 160px;
  margin-bottom: 40px;
`

export const DiningWrapper = styled.div`
  position: relative;
  margin-top: 40px;
  padding-top: 40%;
`

export const DiningImageWrapper = styled.div`
  height: 110px;
  width: 100%;
  ${Tablet} & {
    opacity: 0;
  }
`

export const DiningImage = styled(Img).attrs({
  filename: 'meetus-dining.png',
  fadeIn: false
})`
  ${Mobile} & {
    width: 100%;
    transform: translateY(-85%);
    min-height: 550px;
    max-width: 380px;
    margin: 0 auto;
  }

  ${Tablet} & {
    margin-top: 40px;
  }
`
