import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../i18n'
import SEO from '../../components/seo'
import * as R from 'ramda'
import {
  DiningImage,
  DiningImageWrapper,
  DiningWrapper,
  Header,
  ImageMapWrapper,
  Lexicon,
  MainSection,
  MeetText,
  MeetUsImg,
  Mobile,
  PlateDescription,
  PlatesContainer,
  PlateTitle,
  PlateWrapper,
  ProducersHeader,
  ProducersHeaderTablet,
  ProducersImage,
  RightAlignText,
  SophiaImage,
  SophiaText,
  SophiaWrapper,
  Tablet
} from './css'

const Plates = ({ lexicon }) => {
  const letters = R.keys(lexicon)
  return (
    <PlatesContainer>
      {letters.map(letter => (
        <div key={letter}>
          {lexicon[letter].map((plate, idx) => (
            <PlateWrapper key={idx}>
              <PlateTitle isFirst={idx === 0}>
                {idx === 0 && <span>{letter}</span>}
                {plate.title}
              </PlateTitle>
              <PlateDescription>{plate.description}</PlateDescription>
            </PlateWrapper>
          ))}
        </div>
      ))}
    </PlatesContainer>
  )
}

const SpecialImage = ({ t }) => (
  <ImageMapWrapper>
    <ProducersImage />
    <SophiaWrapper>
      <SophiaImage />
      <SophiaText>{t('meet_sophia_text')}</SophiaText>
      <ProducersHeader>{t('meet_local_producers')}</ProducersHeader>
    </SophiaWrapper>
  </ImageMapWrapper>
)

const DiningSection = ({ t }) => (
  <DiningWrapper>
    <Header>{t('meet_dining_header')}</Header>
    <MeetText>{t('meet_dining_top')}</MeetText>
    <DiningImageWrapper>
      <DiningImage />
    </DiningImageWrapper>
    <MeetText>{t('meet_dining_bottom')}</MeetText>
  </DiningWrapper>
)

const MeetUs = () => {
  const { t } = useTranslation()
  const [lexicon, setLexicon] = useState({})
  const plates = t('lexicon') || []

  useEffect(() => {
    setLexicon(R.groupBy(R.pathOr('', ['title', 0]), plates))
  }, [plates])
  return (
    <>
      <SEO
        title="Meet us"
        keywords={[`nikos gallop`, `meet us`, 'lexicon', 'restaurant story']}
      />
      <MainSection>
        <Mobile>
          <Header>{t('meet_the_story')}</Header>
          <MeetText>{t('meet_story_text')}</MeetText>
          <MeetUsImg />
          <RightAlignText>{t('meet_provide_text')}</RightAlignText>
          <SpecialImage t={t} />
          <RightAlignText>{t('meet_producers_text')}</RightAlignText>
          <DiningSection t={t} />
        </Mobile>
        <Tablet>
          <div>
            <Header>{t('meet_the_story')}</Header>
            <MeetText>{t('meet_story_text')}</MeetText>
            <SpecialImage t={t} />
            <DiningSection t={t} />
          </div>
          <div>
            <MeetUsImg />
            <RightAlignText>{t('meet_provide_text')}</RightAlignText>
            <ProducersHeaderTablet>
              {t('meet_local_producers')}
            </ProducersHeaderTablet>
            <RightAlignText>{t('meet_producers_text')}</RightAlignText>
            <DiningImage />
          </div>
        </Tablet>
      </MainSection>
      <Lexicon>
        <Header>{t('meet_lexicon')}</Header>
        <Plates lexicon={lexicon} />
      </Lexicon>
    </>
  )
}

export default MeetUs
